<template>
  <div class="list-user">
    <div class="list-user-table">
      <div class="mb-2 row">
        <h2
          class="col-sm-12 col-md-6 col-lg-6 text-truncate-mess font-title font-weight-bold"
        >
          売上管理（ランキング）
        </h2>
        <div
          class="px-0 col-sm-12 col-md-6 col-lg-6 tex-left text-md-right text-sm-left"
        >
          <button class="button-create-user" @click="downloadCSV()">
            CSV出力
          </button>
          <button
            v-on:click="gotoDashboard()"
            class="button-back-dashboard back-btn"
          >
            戻る
          </button>
        </div>
      </div>
      <div class="row">
        <label class="col-sm-12 font-weight-bold pl-3">期間指定</label>
        <div class="row-input col-sm-4 col-md-4 mb-3 mb-sm-0 col-lg-3">
          <date-picker
            class="w-100"
            v-model="month_from"
            type="date"
            locale="ja"
            valueType="YYYY-MM-DD"
            @change="dateSelectedDateFrom"
          ></date-picker>
        </div>
        <span
          class="col-auto custom-span text-center p-0 justify-content-center"
          style="line-height: 25px"
          >~</span
        >
        <div class="row-input col-sm-4 col-md-4 mb-3 mb-sm-0 col-lg-3">
          <div class="fd-admin-listproperty-card-filter filter-date">
            <date-picker
              class="w-100"
              v-model="month_to"
              type="date"
              locale="ja"
              valueType="YYYY-MM-DD"
              @change="dateSelectedDateTo"
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center mt-4 mb-4">
        <b-button
          v-on:click.prevent="getlistSaleManagementRanks()"
          variant="info"
          class="mr-4"
          >絞り込み検索</b-button
        >
        <b-button
          variant="secondary"
          class="mr-4"
          v-on:click="resetFieldSearch()"
          >リセット</b-button
        >
      </div>
      <div class="d-flex position-relative">
        <div>
          <p class="font-weight-bold">
            {{ yearfrom }}年{{ monthfrom }}月{{ dayfrom }}日 ～ {{ yearto }}年{{
              monthto
            }}月{{ dayto }}日
          </p>
        </div>
      </div>
      <Tables
        :items="listSaleManagementRank.data"
        :fields="fields"
        :itemsPerPageSelect="{}"
      >
        <template v-slot:id="{ index }">
          <td>{{ index + 1 }}</td>
        </template>
        <template v-slot:name="{ item }">
          <td>
            <router-link
              style="color: black"
              :to="{
                name: $route.params.shopId ? 'edit user' : 'edit user domain',
                params: {
                  shopId: shop_id,
                  id: item.id,
                },
              }"
            >
              {{ item.name }}
            </router-link>
          </td>
        </template>
        <template v-slot:total_revenue="{ item }">
          <td>
            {{ Number(item.total_revenue).toLocaleString("ja") }}
          </td>
        </template>
        <template v-slot:total_quantity="{ item }">
          <td>
            {{ Number(item.total_quantity).toLocaleString("ja") }}
          </td>
        </template>
        <template v-slot:action="{ item }">
          <td class="py-2 pl-2 pr-1 d-flex justify-content-center">
            <div>
              <!-- <router-link
                :to="{
                  name: 'purchase user',
                  params: {
                    shopId: $route.params.shopId,
                    id: item.user_id,
                  },
                  query: {
                    date_from: dateFromSend,
                    date_to: dateToSend,
                  },
                }"
                class="d-flex flex-column align-items-center"
              > -->
              <CButton
                class="mx-1 btn-other"
                color="success"
                square
                variant="outline"
                size="sm"
                v-b-modal.modal-comfirm
                v-on:click="purchase(item.user_id, dateFromSend, dateToSend)"
              >
                購入履歴
              </CButton>
              <!-- </router-link> -->
            </div>
          </td>
        </template>
      </Tables>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listSaleManagementRank.total"
      >
        <p class="font-weight-bold">
          {{ listSaleManagementRank.total }}件中{{
            listSaleManagementRank.data.length
          }}件を表示
        </p>
      </div>
      <div
        class="d-flex justify-content-center position-relative"
        v-if="listSaleManagementRank.last_page"
      >
        <CPagination
          v-if="listSaleManagementRank.total"
          :activePage.sync="page"
          :pages="listSaleManagementRank.last_page"
          align="center"
        />
        <!-- <div class="custom-selectLimitPage">
          <b-form-select
            v-model="limit"
            tag-placeholder="Add this as new category"
            :options="listLimitRecordPage"
            @change="changeLimitPage()"
          ></b-form-select>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Tables from "../../components/common/Tables";
import { tableFields } from "../../utils/table-fields.js";
import { mapActions, mapGetters } from "vuex";
import Encoding from "encoding-japanese";
import { Urls } from "../../utils/urls.js";
import { Api } from "../../utils/http-common.js";
import { Constants } from "../../utils/constants";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ja";
DatePicker.locale("ja");
// import $ from "jquery";
export default {
  name: "listSaleManagementRanks",
  data() {
    return {
      fields: tableFields.SALE_MANAGEMENT_RANK,
      listLimitRecordPage: [
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 200, text: "200" },
      ],
      dataModal: "",
      total_amount: "",
      title: "",
      name: "",
      status_order: "",
      month_from: "",
      month_to: "",
      limit: 50,
      page: 1,
      messageError: {},
      messageResponse: "",
      messagerErr: "",
      messageSuccess: "",
      lang: "ja",
      monthfrom: "",
      monthto: "",
      yearfrom: "",
      yearto: "",
      total_revenue: "",
      total: "",
      dayfrom: "",
      dayto: "",
      dateFromSend: "",
      dateToSend: "",
      shop_id: localStorage.getItem(Constants.SHOP_ID),
    };
  },
  components: {
    Tables,
    DatePicker,
  },
  created() {
    this.getlistSaleManagementRanks(1);
  },
  computed: {
    ...mapGetters([
      "listSaleManagementRank",
      "dateSaleManagementRank",
      "message",
      "success",
      "error",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listSaleManagementRank() {
      this.json_data = this.listSaleManagementRank.data;
      this.page = this.listSaleManagementRank.current_page;
    },
    page() {
      this.getlistSaleManagementRanks(this.page);
    },
    // dateSaleManagementRank() {
    //   if (this.dateSaleManagementRank.data[0] !== null) {
    //     const dateStartDefault = new Date(
    //       this.dateSaleManagementRank.data[0].start_day
    //     );
    //     this.yearfrom = dateStartDefault.getFullYear();
    //     this.monthfrom = dateStartDefault.getMonth() + 1;
    //     this.dayfrom = dateStartDefault.getDate();
    //     const dateEndDefault = new Date(
    //       this.dateSaleManagementRank.data[0].end_day
    //     );
    //     this.yearto = dateEndDefault.getFullYear();
    //     this.monthto = dateEndDefault.getMonth() + 1;
    //     this.dayto = dateEndDefault.getDate();
    //   }
    // },
  },
  methods: {
    ...mapActions({
      getDetailSaleManagetmentRank: "getDetailSaleManagetmentRank",
    }),
    ...mapActions({
      getDateSaleManagetmentRank: "getDateSaleManagetmentRank",
    }),
    changeLang() {
      this.lang === "ja";
    },
    getlistSaleManagementRanks(page) {
      if (page === undefined) {
        page = this.page;
      }
      const formData = {
        page: page,
        data: {
          shop_id: this.shop_id,
          limit: this.limit,
          start_date: this.month_from,
          end_date: this.month_to,
        },
      };
      this.getDetailSaleManagetmentRank(formData);
      this.getDateSaleManagetmentRank(formData);
    },
    changeLimitPage() {
      this.getlistSaleManagementRanks(1);
    },
    async csvExport(datas, dataRequest) {
      const url = `${Urls.ADMIN}/${Urls.SALES_MANAGEMENT}/${Urls.RANK}/${Urls.EXPORT}`;
      const method = "POST";
      let response = await Api.adminRequestServer({
        method,
        url,
        data: dataRequest,
      });
      var { data } = response.data;
      if (data.success === false) return datas;
      datas += data.data;
      this.index++;
      if (this.index % Constants.LIMIT_CSV == 0) {
        const getDate = new Date();
        const nameExcel =
          "売上管理（ランキング）" +
          [
            getDate.getFullYear(),
            getDate.getMonth() + 1,
            getDate.getDate(),
          ].join("_") +
          " " +
          [getDate.getHours(), getDate.getMinutes(), getDate.getSeconds()].join(
            ":"
          );
        const CSVName = nameExcel + ".csv";
        let file = "順位,ユーザー名,売上（円）,購入件数\n";
        file += datas;
        datas = [];
        file = new Encoding.stringToCode(file);
        file = Encoding.convert(file, "sjis", "unicode");
        file = new Uint8Array(file);
        var fileURL = window.URL.createObjectURL(
          new Blob([file], { type: "text/csv;charset=sjis" })
        );
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", CSVName);
        document.body.appendChild(fileLink);
        fileLink.click();
      }
      // if (data.data.status == "未承認") {
      //   dataRequest.id = data.data.id;
      //   datas = await this.csvExport(datas, dataRequest);
      //   return datas;
      // }
      return datas;
    },
    async downloadCSV() {
      this.messageError = {};
      this.messageResponse = "";
      this.messagerErr = "";

      try {
        this.index = 0;
        var datas = [];
        let dataRequest = {
          id: 0,
          shop_id: this.shop_id,
          start_date: this.month_from,
          end_date: this.month_to,
        };
        datas = await this.csvExport(datas, dataRequest);
        if (Object.keys(datas).length == 0) {
          if (this.index == 0) {
            this.messageResponse = "データがありません";
          } else {
            this.messageSuccess = "ダウンロードが完了しました";
          }
          // $("body").removeClass("wait");
          return;
        }
        const getDate = new Date();
        const nameExcel =
          "売上管理（ランキング）" +
          [
            getDate.getFullYear(),
            getDate.getMonth() + 1,
            getDate.getDate(),
          ].join("_") +
          " " +
          [getDate.getHours(), getDate.getMinutes(), getDate.getSeconds()].join(
            ":"
          );
        const CSVName = nameExcel + ".csv";
        dataRequest.start_date.replaceAll("-", "") +
          "-" +
          dataRequest.end_date.replaceAll("-", "") +
          "_" +
          (Math.floor(this.index / Constants.LIMIT_CSV) + 1) +
          ".csv";
        let file = "順位,ユーザー名,売上（円）,購入件数\n";
        file += datas;
        file = new Encoding.stringToCode(file);
        file = Encoding.convert(file, "sjis", "unicode");
        file = new Uint8Array(file);
        var fileURL = window.URL.createObjectURL(
          new Blob([file], { type: "text/csv;charset=sjis" })
        );
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", CSVName);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.messageSuccess = "ダウンロードが完了しました";
        // $("body").removeClass("wait");
      } catch (e) {
        // $("body").removeClass("wait");
        this.messageResponse = "システムエラーが発生しました";
        console.log(e);
      }
    },
    gotoDashboard() {
      this.$router.go(-1);
    },
    dateSelectedDateFrom() {
      this.dateFromSend = this.month_from;
      if (this.month_from !== null) {
        const dateForm = this.month_from.split("-");
        this.yearfrom = dateForm[0];
        this.monthfrom = dateForm[1];
        this.dayfrom = dateForm[2];
      }
    },
    dateSelectedDateTo() {
      this.dateToSend = this.month_to;
      if (this.month_to !== null) {
        const dateForm = this.month_to.split("-");
        this.yearto = dateForm[0];
        this.monthto = dateForm[1];
        this.dayto = dateForm[2];
      }
    },
    resetFieldSearch() {
      this.month_from = "";
      this.month_to = "";
      const formData = {
        page: 1,
        data: {
          shop_id: this.shop_id,
          limit: this.limit,
          start_date: this.month_from,
          end_date: this.month_to,
        },
      };
      this.getDetailSaleManagetmentRank(formData);
      this.getDateSaleManagetmentRank(formData);
    },
    purchase(user_id, dateFromSend, dateToSend) {
      if (this.$route.params.shopId) {
        this.$router.push({
          name: "purchase user",
          params: {
            shopId: this.$route.params.shopId,
            id: user_id,
          },
          query: {
            date_from: dateFromSend,
            date_to: dateToSend,
          },
        });
      } else {
        this.$router.push({
          name: "purchase user domain",
          params: {
            id: user_id,
          },
          query: {
            date_from: dateFromSend,
            date_to: dateToSend,
          },
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-span {
  @media (max-width: 420px) {
    display: none;
  }
}
</style>
